import React from "react";
import { storyblokEditable } from "@storyblok/react";
import { Link } from "react-router-dom";

const ConnectForm = ({ blok }) => {
  return (
    <div
      className="font-aileron flex justify-center items-center px-16 py-20 bg-teal-950 max-md:px-5 bg-ink-blue"
      {...storyblokEditable(blok)}
      key={blok?._uid}
    >
      <div className="justify-center mt-10 w-full max-w-[1120px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 justify-around">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full z-[10]">
            <div className="flex flex-col grow self-stretch pb-20 text-lg leading-7 max-md:mt-10 max-md:max-w-full">
              <div className="text-4XL font-heavy font-aileron leading-10 text-white max-md:max-w-full">
                {blok?.title}
              </div>
              {blok?.title && (
                <div className="bg-light-green h-[2px] w-[3rem] md:w-[9rem] bottom-5 mt-6" />
              )}
              <div className="font-lora mt-11 leading-7 text-white max-md:mt-10 max-md:max-w-full text-large-regular">
                {blok.description}
              </div>
              {blok.SocialMedia.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex gap-5 mt-12 whitespace-nowrap max-md:flex-wrap max-md:mt-10"
                  >
                    <img
                      loading="lazy"
                      src={item.Icon.filename}
                      alt={item.Icon.filename}
                      className="shrink-0 aspect-square w-[70px]"
                    />
                    <div className="flex flex-col flex-1 self-start max-md:max-w-full">
                      <div className="font-black text-gray-300 max-md:max-w-full text-white text-large-regular font-heavy">
                        {item.title}
                      </div>
                      <Link to={item.Link.url}>
                        <div className="mt-2.5 text-white underline max-md:max-w-full text-large-regular whitespace-break-spaces">
                          {item.Link.url}
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col w-5/12 max-md:ml-0 max-md:w-full z-[1] relative ">
            <img
              className="absolute right-[-43px] top-20 z-[-1] hidden md:block"
              src="../images/DottedShape.svg"
              alt="forms"
            />
            <img
              className="absolute left-[-29px] bottom-[-29px] z-[-1] hidden md:block"
              src="../images/DottedShape.svg"
              alt="forms"
            />
            <div className="flex flex-col flex-wrap grow justify-center bg-white max-md:mt-10 max-md:max-w-full rounded-md">
              <iframe
                name="submit_target"
                title="Form Submission Status"
                className="Success-Message"
                style={{
                  display: "none",
                  width: "100%",
                  height: "auto",
                }}
                onLoad={() => {
                  document.querySelector("#form").style.display = "none";
                  document.querySelector(".Success-Message").style.display =
                    "block";
                }}
              ></iframe>
              <div dangerouslySetInnerHTML={{ __html: blok.zohoLink }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectForm;
